import React from 'react'
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/markdown-github.css";
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PageLink = ({name, url}) => (
  <Link to={url}
        className="inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
        activeClassName={"border-b-2 border-indigo-600 font-black"}
        partiallyActive={true}
  >{name}</Link>
)

const Page = ({ data }) => {

  const { page, pages } = data
  const title = page.title
  const links = pages.edges;

  return (
    <Layout headerClass="relative bg-white">
      <SEO title={title} />
      <div className="flex flex-col md:flex-row md:min-h-screen w-full">
        <div
          className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none">
          <div className="flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last">
            <nav className="flex-grow hidden md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap">
              {links.map(({ node }) => (
                  <PageLink name={node.title} url={`/parish_council/${node.slug}`} key={node.id} />
                )
              )}
              <p className="mt-8">North Lincs Council links</p>
              <a
                href="https://www.northlincs.gov.uk/your-council/have-your-say/members-register-of-interest/conduct-of-members/"
                className="inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent"
                target="_blank"
                rel="noreferrer"
              >Code of Conduct <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></a>
              <a
                href="https://northlincolnshire.moderngov.co.uk/mgParishCouncilDetails.aspx?ID=287&LS=3"
                className="inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent"
                target="_blank"
                rel="noreferrer"
              >Register of Interests <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></a>
            </nav>
          </div>
        </div>
        <div className="flex flex-col w-full md:pl-4">
          <div className="flex-shrink-0 py-0 flex flex-row">
            <div className="markdown-body">
              <div dangerouslySetInnerHTML={{__html: page.content}}></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
    query($slug: String) {
        page: wpPage(slug: {eq: $slug}) {
            content
            slug
            title
        },
        pages: allWpPage(sort: {fields: menuOrder, order: ASC}) {
            edges {
                node {
                    uri
                    slug
                    title
                    menuOrder
                    id
                }
            }
        }
    }
`;
